@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");

:root {
    --blue: 30, 144, 255;
    --white: #ffffff;
    --purple-dark: 111, 110, 196;
    --purple-light: 179, 157, 246;
}

.opacity-0 {
    opacity: 0%;
}

.opacity-100 {
    opacity: 100%;
}
.purple-double-gradient {
    background: linear-gradient(180deg, rgba(var(--purple-dark), 0.01), rgba(var(--bs-body-bg-rgb), 1) 85%),
        radial-gradient(ellipse at top left, rgba(var(--purple-dark), 0.5), transparent 50%),
        radial-gradient(ellipse at top right, rgba(var(--purple-dark), 0.5), transparent 50%),
        radial-gradient(ellipse at center right, rgba(var(--purple-light), 0.5), transparent 50%),
        radial-gradient(ellipse at center left, rgba(var(--purple-dark), 0.5), transparent 50%);
}

.idle-detected-warning {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 50px;
}

.accordion-button::after {
    content: none !important;
    background-image: none !important;
}

.override-bootstrap > h1,
h2,
h3,
h4 {
    margin-bottom: 0px !important;
}

.override-bootstrap > li,
ul {
    margin-bottom: 0px !important;
    white-space: normal;
}

.override-bootstrap > p {
    margin-bottom: 0px !important;
}

.w-fit-content {
    width: fit-content !important;
}

.w-max-content {
    width: max-content !important;
}
.caret-off::before {
    display: none !important;
}
.caret-off::after {
    display: none !important;
}

.dragover-area {
    display: none;
    width: 100%;
    color: white;
    flex-direction: column;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    z-index: 999999999;
    justify-content: center;
    align-items: center;
}

.fullscreen {
    position: fixed;
    top: 0px;
    height: 100%;
    width: 100%;
    background-color: #ccc;
    pointer-events: all;
}

.max-height-60 {
    max-height: 60vh !important;
    overflow: auto;
}

.w-md-75 {
    width: 75% !important;
}

.avatar-col-container {
    overflow: auto;
    max-height: 60vh;
    min-height: 60vh;
    height: 100% !important;
}
.pointer-cursor {
    cursor: pointer !important;
}

.tooltip {
    z-index: 99999999999999999 !important;
}

.max-z {
    z-index: 999999999999999999999 !important;
}

.roomlayout {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns:
        [main] auto
        [sidebar] minmax(0, min-content);

    grid-template-rows: auto;

    overflow: hidden;
    position: relative;
    pointer-events: none;
}

a-scene {
    position: initial;
}

.react-tooltip {
    z-index: 9999999999999999999 !important;
}

#tooltip-container {
    z-index: 9999999999999999999 !important;
}

.toolbar {
    height: 60px;
    display: flex;
    padding: 8px;
    background-color: rgba(255, 255, 255, 0.5);
    pointer-events: all;
}

.toolbar-mobile {
    display: flex;
    padding: 7px;
    background-color: rgba(255, 255, 255, 0.5);
    pointer-events: all;
}

.root {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 3;
    position: absolute;
    pointer-events: none;
    overflow-y: auto;
    background: #fff;
}

.three-root {
    background: none;
}

.all-pointer-events {
    pointer-events: all;
}

.clickable {
    pointer-events: all !important;
}

#css2drenderer {
    background: transparent !important;
    width: 0;
    height: 0;
}

.css2d-question-container {
    cursor: auto;
}

.css2d-container {
    padding: 18px;
    width: 280px;
    background: white;
    border-radius: 8px;
    transition: visibility 0s, opacity 0.5s linear;
    overflow: auto;
    max-height: 60vh !important;
}

.css2d-container-placeholder {
    padding: 5px;
    background: white;
    border-radius: 8px;
    transition: visibility 0s, opacity 0.5s linear;
}

.sketch-picker {
    bottom: 230px;
    z-index: 999999;
    position: absolute;
}

.fixed-bottom {
    z-index: 100 !important;
}

.__floater .__floater__open {
    z-index: 999999999 !important;
}

.toast-container {
    z-index: 20 !important;
}

.btn-purple {
    background-color: #bdacf3;
    color: white;
    padding: 10px;
    font-size: 1rem;
    border-radius: 4px;
    border: none;
}

.btn-purple-dark {
    background-color: #bdacf3;
    color: white;
    padding: 10px;
    font-size: 1rem;
    border-radius: 4px;
    border: none;
}

.btn-toolbar-button {
    border: none;
    color: #6f6ec4 !important;
    background-color: #fff !important;
    justify-content: center !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 48px;
    height: 48px;
}

.btn-toolbar-button:hover {
    border: none;
    color: #6f6ec4 !important;
    background-color: #ccc !important;
    justify-content: center !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 48px;
    height: 48px;
}

.toolbarGroup > :first-child .toolbar-container {
    border-radius: 4px 0px 0px 4px;
}

.toolbarGroup > :last-child .toolbar-container {
    border-radius: 0px 4px 4px 0px;
}

.toolbarGroup > :only-child .toolbar-container {
    border-radius: 4px 4px 4px 4px;
}

.toolbarGroup > :only-child {
    border-radius: 4px 4px 4px 4px;
}

.toolbarGroupRight {
    display: flex;
    margin-right: 5px;
}

.toolbarGroupRight > :first-child {
    border-radius: 4px 0px 0px 4px;
}

.toolbarGroupRight > :last-child {
    border-radius: 0px 4px 4px 0px;
}

.toolbarGroupRight > :last-child .toolbar-container {
    border-radius: 0px 4px 4px 0px;
}

.toolbarGroupRight > :only-child {
    border-radius: 4px 4px 4px 4px;
}

.bg-semitransparent {
    background: rgba(255, 255, 255, 0.125);
}

.watermark {
    pointer-events: none !important;
    width: 300px;
    position: fixed;
    top: 25px;
    left: 25px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.disabled-question {
    pointer-events: none !important;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.disable-select {
    pointer-events: none !important;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.btn-purple-dark {
    background-color: #6f6ec4 !important;
    color: #fff !important;
}

.text-purple-dark {
    color: #6f6ec4 !important;
}

.chat-box:focus {
    background: #fff !important;
    transition: background 0.3s ease-in-out;
}

.chat-box:hover {
    background: #fff !important;
    transition: background 0.3s ease;
}

.bg-purple-light {
    background-color: #b39df6 !important;
    color: #fff !important;
}

.bg-purple-dark {
    background-color: #6f6ec4 !important;
    color: #fff !important;
}

.btn-purple-dark:hover {
    background-color: #7e81c6 !important;
    color: #fff !important;
}

.btn-purple-dark:active {
    background-color: #323a3f !important;
    color: #fff !important;
}

.ignore-cursor {
    pointer-events: none !important;
}

.scrollbar-left {
    direction: rtl;
}

.text-rtl-reset {
    direction: ltr;
}

.selector-card:hover {
    cursor: pointer;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.selector-card:active {
    border-width: 5px;
    filter: brightness(60%);
}

.reset-style-accordion {
    --bs-accordion-active-color: #333;
    --bs-accordion-btn-color: #000;
    --bs-accordion-active-bg: #fff;
    --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;

    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.accordion-small-button .accordion-button {
    padding: 0.5rem !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}

.ui {
    z-index: 10;
    pointer-events: all;
}

.no-cursor {
    cursor: none;
}

.html2canvas-card {
    background-color: #000;
    width: fit-content;
    color: #fff;
    padding: 0.5rem !important;
    border-radius: 4px;
}

.form-check-label {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.incorrect-question-bg {
    background-color: #f7adad;
}

.correct-question-bg {
    background-color: #f7adad;
}

.dim {
    filter: brightness(70%);
}

.selected-dim {
    backdrop-filter: brightness(70%);
}

.popover {
    z-index: 2 !important;
}

.no-select {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
